import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

interface AddIndustryDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onAdd: (name: string) => void;
}

const AddIndustryDialog = ({ isOpen, onOpenChange, onAdd }: AddIndustryDialogProps) => {
  const [industryName, setIndustryName] = useState('');

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Show Industry</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 pt-4">
          <div>
            <Label htmlFor="name">Industry Name</Label>
            <Input
              id="name"
              value={industryName}
              onChange={(e) => setIndustryName(e.target.value)}
              placeholder="Enter industry name"
            />
          </div>
          <Button 
            onClick={() => {
              if (industryName.trim()) {
                onAdd(industryName);
                setIndustryName('');
              }
            }}
            className="w-full"
          >
            Add Industry
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddIndustryDialog;