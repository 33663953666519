import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface ContactFieldsProps {
  formData: {
    phone: string;
    mobile: string;
    email: string;
    web: string;
  };
  onChange: (field: string, value: string) => void;
}

export const ContactFields = ({ formData, onChange }: ContactFieldsProps) => {
  return (
    <>
      <div>
        <Label htmlFor="phone">Phone</Label>
        <Input
          id="phone"
          value={formData.phone}
          onChange={(e) => onChange("phone", e.target.value)}
          className="h-8"
        />
      </div>

      <div>
        <Label htmlFor="mobile">Mobile</Label>
        <Input
          id="mobile"
          value={formData.mobile}
          onChange={(e) => onChange("mobile", e.target.value)}
          className="h-8"
        />
      </div>

      <div className="col-span-2">
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          value={formData.email}
          onChange={(e) => onChange("email", e.target.value)}
          className="h-8"
        />
      </div>

      <div className="col-span-2">
        <Label htmlFor="web">Website</Label>
        <Input
          id="web"
          value={formData.web}
          onChange={(e) => onChange("web", e.target.value)}
          className="h-8"
        />
      </div>
    </>
  );
};