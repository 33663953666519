import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";

interface ShowIndustryItemProps {
  name: string;
  isSelected: boolean;
  onSelect: () => void;
  onDeleteClick: () => void;
}

const ShowIndustryItem = ({
  name,
  isSelected,
  onSelect,
  onDeleteClick,
}: ShowIndustryItemProps) => {
  return (
    <div
      onClick={onSelect}
      className={`flex items-center gap-2 p-1 rounded cursor-pointer hover:bg-gray-100 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
    >
      <div className="w-2 h-2 rounded-full bg-gray-500" />
      <span className="text-sm flex-grow">{name}</span>
      <Button
        variant="ghost"
        size="icon"
        className="h-6 w-6 hover:bg-red-100"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick();
        }}
      >
        <Trash2 className="h-4 w-4 text-gray-500" />
      </Button>
    </div>
  );
};

export default ShowIndustryItem;