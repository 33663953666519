import { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Store, Plus } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import ShowIndustryItem from "@/components/show-industries/ShowIndustryItem";
import AddIndustryDialog from "@/components/show-industries/AddIndustryDialog";

interface Industry {
  id: number;
  name: string;
  color: string;
}

interface IndustriesSectionProps {
  selectedIndustry: string | null;
  onIndustrySelect: (industry: string) => void;
}

const IndustriesSection = ({
  selectedIndustry,
  onIndustrySelect,
}: IndustriesSectionProps) => {
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [industries, setIndustries] = useState<Industry[]>([]);

  useEffect(() => {
    fetchIndustries();

    // Set up real-time subscription
    const channel = supabase
      .channel('show-industries-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'show_industries'
        },
        () => {
          console.log('Show industries changed, refreshing...');
          fetchIndustries();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const fetchIndustries = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to view industries",
          variant: "destructive",
        });
        return;
      }

      console.log('Fetching show industries...');
      const { data, error } = await supabase
        .from('show_industries')
        .select('*')
        .eq('user_id', session.user.id)
        .order('name');

      if (error) throw error;

      console.log('Fetched show industries:', data);
      if (data) {
        setIndustries(data);
      }
    } catch (error: any) {
      console.error('Error fetching industries:', error);
      toast({
        title: "Error",
        description: "Failed to fetch industries",
        variant: "destructive",
      });
    }
  };

  const handleDeleteIndustry = async (industryName: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to delete industries",
          variant: "destructive",
        });
        return;
      }

      console.log('Deleting industry:', industryName);
      const { error } = await supabase
        .from('show_industries')
        .delete()
        .eq('name', industryName)
        .eq('user_id', session.user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Industry deleted successfully",
      });

      fetchIndustries();
    } catch (error: any) {
      console.error('Error deleting industry:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const handleAddIndustry = async (name: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to add industries",
          variant: "destructive",
        });
        return;
      }

      const { error } = await supabase
        .from('show_industries')
        .insert([{
          name: name,
          color: 'bg-gray-500',
          user_id: session.user.id
        }]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Industry added successfully",
      });

      setIsOpen(false);
      fetchIndustries();
    } catch (error: any) {
      console.error('Error adding industry:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  return (
    <Card>
      <CardHeader className="bg-gray-50 border-b pb-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Store className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Show Industries</CardTitle>
          </div>
          <Button 
            variant="ghost" 
            size="icon"
            className="h-8 w-8"
            onClick={() => setIsOpen(true)}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <div className="p-2">
        <div className="space-y-0.5">
          {industries.map((industry) => (
            <ShowIndustryItem
              key={industry.id}
              name={industry.name}
              isSelected={selectedIndustry === industry.name}
              onSelect={() => onIndustrySelect(industry.name)}
              onDeleteClick={() => handleDeleteIndustry(industry.name)}
            />
          ))}
        </div>
      </div>
      <AddIndustryDialog
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        onAdd={handleAddIndustry}
      />
    </Card>
  );
};

export default IndustriesSection;