import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Plus, X, Factory } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface IndustriesPanelProps {
  onIndustrySelect: (industry: string) => void;
}

const IndustriesPanel = ({ onIndustrySelect }: IndustriesPanelProps) => {
  const [industries, setIndustries] = useState<{ id: number; name: string; color: string; }[]>([]);
  const [newIndustry, setNewIndustry] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    fetchIndustries();

    const channel = supabase
      .channel('industries-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'industries'
        },
        () => {
          fetchIndustries(); // Refetch industries when changes occur
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const fetchIndustries = async () => {
    const { data, error } = await supabase
      .from('industries')
      .select('*')
      .order('name');

    if (error) {
      console.error('Error fetching industries:', error);
      toast({
        title: "Error",
        description: "Failed to fetch industries",
        variant: "destructive",
      });
      return;
    }

    if (data) {
      setIndustries(data);
    }
  };

  const removeIndustry = async (industryId: number) => {
    try {
      const { error } = await supabase
        .from('industries')
        .delete()
        .eq('id', industryId);

      if (error) throw error;

      toast({
        title: "Industry Removed",
        description: "Industry has been removed successfully.",
      });

      // Refresh the industries list
      fetchIndustries();
    } catch (error: any) {
      console.error('Error removing industry:', error);
      toast({
        title: "Error",
        description: "Failed to remove industry",
        variant: "destructive",
      });
    }
  };

  const handleAddIndustry = async () => {
    if (!newIndustry.trim()) {
      toast({
        title: "Missing Information",
        description: "Please enter an industry name.",
        variant: "destructive",
      });
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        toast({
          title: "Error",
          description: "You must be logged in to add industries",
          variant: "destructive",
        });
        return;
      }

      const colors = ["bg-blue-500", "bg-green-500", "bg-purple-500", "bg-red-500", "bg-yellow-500"];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];

      const { error } = await supabase
        .from('industries')
        .insert({
          name: newIndustry,
          color: randomColor,
          user_id: user.id
        });

      if (error) throw error;

      setNewIndustry("");
      toast({
        title: "Industry Added",
        description: "Industry has been added successfully.",
      });
    } catch (error: any) {
      console.error('Error in handleAddIndustry:', error);
      toast({
        title: "Error",
        description: "Failed to add industry",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50 border-b py-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Factory className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Industries</CardTitle>
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <Button 
                className="bg-gray-100 text-gray-700 hover:bg-gray-200"
                size="icon"
              >
                <Plus className="h-4 w-4" />
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle className="text-xl font-medium text-gray-700">Add New Industry</DialogTitle>
              </DialogHeader>
              <div className="space-y-4 py-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium">Industry Name</label>
                  <Input
                    type="text"
                    value={newIndustry}
                    onChange={(e) => setNewIndustry(e.target.value)}
                    placeholder="Enter industry name"
                  />
                </div>
                <Button 
                  className="w-full bg-gray-700 hover:bg-gray-800 text-white"
                  onClick={handleAddIndustry}
                >
                  Add Industry
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </CardHeader>
      <CardContent className="p-2">
        <div className="space-y-0.5">
          {industries.map((industry) => (
            <div
              key={industry.id}
              className="flex items-center justify-between gap-1 group cursor-pointer hover:bg-gray-50 py-0.5 px-1 rounded"
              onClick={() => onIndustrySelect(industry.name)}
            >
              <div className="flex items-center gap-1">
                <div className={`h-1 w-1 rounded-full ${industry.color}`}></div>
                <span className="text-xs">{industry.name}</span>
              </div>
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity"
                onClick={(e) => {
                  e.stopPropagation();
                  removeIndustry(industry.id);
                }}
              >
                <X className="h-3 w-3 text-gray-500" />
              </Button>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default IndustriesPanel;