import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

export const RegistrationForm = () => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: ''
  });

  const handleUserRegistration = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    
    if (userData.password !== userData.confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match",
        variant: "destructive"
      });
      return;
    }

    try {
      setLoading(true);
      console.log('Starting registration process...');
      
      // Store credentials temporarily for after payment
      localStorage.setItem('tempEmail', userData.email);
      localStorage.setItem('tempPassword', userData.password);

      // Create the user account
      console.log('Creating user account...');
      const { data, error } = await supabase.auth.signUp({
        email: userData.email,
        password: userData.password,
        options: {
          data: {
            username: userData.username,
          }
        }
      });

      if (error) {
        console.error('Error creating user:', error);
        throw error;
      }

      if (data.user) {
        console.log('User created successfully, creating checkout session...');
        // Create checkout session
        const { data: checkoutData, error: checkoutError } = await supabase.functions.invoke('create-checkout');
        
        if (checkoutError) {
          console.error('Error creating checkout:', checkoutError);
          throw checkoutError;
        }

        if (checkoutData?.url) {
          console.log('Redirecting to checkout:', checkoutData.url);
          // Redirect to Stripe Checkout
          window.location.href = checkoutData.url;
        } else {
          throw new Error('No checkout URL received');
        }
      }
    } catch (error: any) {
      console.error('Registration error:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive"
      });
      // Clear stored credentials on error
      localStorage.removeItem('tempEmail');
      localStorage.removeItem('tempPassword');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mb-12">
      <h3 className="text-xl font-semibold mb-6 text-center">Create Your Account</h3>
      <form onSubmit={handleUserRegistration} className="space-y-4">
        <div>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            value={userData.email}
            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
            required
          />
        </div>
        <div>
          <Label htmlFor="username">Username</Label>
          <Input
            id="username"
            type="text"
            value={userData.username}
            onChange={(e) => setUserData({ ...userData, username: e.target.value })}
            required
          />
        </div>
        <div>
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            type="password"
            value={userData.password}
            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
            required
          />
        </div>
        <div>
          <Label htmlFor="confirmPassword">Confirm Password</Label>
          <Input
            id="confirmPassword"
            type="password"
            value={userData.confirmPassword}
            onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
            required
          />
        </div>
        <Button type="submit" className="w-full" disabled={loading}>
          {loading ? "Please wait..." : "Create Account"}
        </Button>
      </form>
    </div>
  );
};