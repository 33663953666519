import Header from "@/components/Header";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const Reports = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Reports</h1>
        
        <Alert className="max-w-2xl">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Work in Progress</AlertTitle>
          <AlertDescription>
            The reports functionality is currently under development and will be available soon. Thank you for your patience!
          </AlertDescription>
        </Alert>
      </div>
    </div>
  );
};

export default Reports;