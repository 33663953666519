import { useState, useCallback, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface AddTradeShowFormProps {
  type: 'exhibiting' | 'visiting';
  selectedIndustry: string | null;
  onClose: () => void;
  onSuccess: () => void;
}

const AddTradeShowForm = ({ type, selectedIndustry, onClose, onSuccess }: AddTradeShowFormProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [industries, setIndustries] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    location: '',
    industry: selectedIndustry || '',
  });

  const fetchIndustries = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to view industries",
          variant: "destructive",
        });
        return;
      }

      console.log('Fetching industries for dropdown...');
      const { data, error } = await supabase
        .from('show_industries')
        .select('*')
        .eq('user_id', session.user.id)
        .order('name');

      if (error) throw error;

      console.log('Fetched industries:', data);
      if (data) {
        setIndustries(data);
        if (selectedIndustry) {
          setFormData(prev => ({ ...prev, industry: selectedIndustry }));
        }
      }
    } catch (error: any) {
      console.error('Error fetching industries:', error);
      toast({
        title: "Error",
        description: "Failed to fetch industries",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchIndustries();

    // Set up real-time subscription for industries
    const channel = supabase
      .channel('show-industries-changes-form')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'show_industries'
        },
        (payload) => {
          console.log('Show industries changed in form, refreshing...', payload);
          fetchIndustries();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const validateForm = useCallback(() => {
    const errors = [];
    if (!formData.name.trim()) errors.push("Show name is required");
    if (!formData.startDate) errors.push("Start date is required");
    if (!formData.endDate) errors.push("End date is required");
    if (!formData.location.trim()) errors.push("Location is required");
    if (!formData.industry) errors.push("Industry is required");
    
    if (formData.startDate && formData.endDate && formData.startDate > formData.endDate) {
      errors.push("Start date must be before end date");
    }
    
    return errors;
  }, [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Starting form submission with data:", formData);
    
    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      console.log("Validation errors:", validationErrors);
      toast({
        title: "Validation Error",
        description: validationErrors.join(", "),
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to add a trade show",
          variant: "destructive",
        });
        return;
      }

      const dateRange = `${formData.startDate} to ${formData.endDate}`;
      
      const insertData = {
        name: formData.name.trim(),
        date: dateRange,
        city: formData.location.trim(),
        type,
        industry: formData.industry,
        user_id: session.user.id,
      };

      console.log('Attempting to insert trade show with data:', insertData);

      const { data, error } = await supabase
        .from('trade_shows')
        .insert([insertData])
        .select();

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      console.log('Trade show added successfully:', data);

      toast({
        title: "Success",
        description: "Trade show added successfully",
      });
      
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error adding trade show:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to add trade show",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="name">Show Name</Label>
        <Input
          id="name"
          value={formData.name}
          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          placeholder="Enter show name"
        />
      </div>

      <div>
        <Label>Industry</Label>
        <Select
          value={formData.industry}
          onValueChange={(value) => setFormData(prev => ({ ...prev, industry: value }))}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select industry" />
          </SelectTrigger>
          <SelectContent>
            {industries.map((industry) => (
              <SelectItem key={industry.id} value={industry.name}>
                {industry.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <Label htmlFor="startDate">Start Date</Label>
          <Input
            id="startDate"
            type="date"
            value={formData.startDate}
            onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
          />
        </div>
        <div>
          <Label htmlFor="endDate">End Date</Label>
          <Input
            id="endDate"
            type="date"
            value={formData.endDate}
            onChange={(e) => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
            min={formData.startDate}
          />
        </div>
      </div>

      <div>
        <Label htmlFor="location">Location</Label>
        <Input
          id="location"
          value={formData.location}
          onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
          placeholder="Enter city"
        />
      </div>
      
      <div className="flex justify-end gap-2">
        <Button
          type="button"
          variant="outline"
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Adding..." : "Add Show"}
        </Button>
      </div>
    </form>
  );
};

export default AddTradeShowForm;