import { Building2, Mail, User, UserCircle } from "lucide-react";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";

interface ProfileInfoProps {
  userInfo: {
    username: string;
    email?: string;
    company_name?: string;
    role?: string;
  };
}

export const ProfileInfo = ({ userInfo }: ProfileInfoProps) => {
  return (
    <>
      <DropdownMenuItem className="flex items-center">
        <UserCircle className="mr-2 h-4 w-4" />
        <span>{userInfo.username}</span>
      </DropdownMenuItem>
      {userInfo.email && (
        <DropdownMenuItem className="flex items-center">
          <Mail className="mr-2 h-4 w-4" />
          <span>{userInfo.email}</span>
        </DropdownMenuItem>
      )}
      {userInfo.company_name && (
        <DropdownMenuItem className="flex items-center">
          <Building2 className="mr-2 h-4 w-4" />
          <span>{userInfo.company_name}</span>
        </DropdownMenuItem>
      )}
      {userInfo.role && (
        <DropdownMenuItem className="flex items-center">
          <User className="mr-2 h-4 w-4" />
          <span>{userInfo.role}</span>
        </DropdownMenuItem>
      )}
    </>
  );
};