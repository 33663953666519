import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Download } from "lucide-react";

export const BackupControl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const createBackup = async () => {
    try {
      setIsLoading(true);
      
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('Not authenticated');

      const response = await fetch(
        'https://xdhkcgssdbisyhgweaql.supabase.co/functions/v1/create-backup',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        }
      );

      const result = await response.json();
      
      if (!response.ok) throw new Error(result.error || 'Failed to create backup');

      toast({
        title: "Backup Created",
        description: `Backup file: ${result.fileName}`,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold mb-4">System Backup</h2>
      <Button 
        onClick={createBackup} 
        disabled={isLoading}
        className="w-full"
      >
        <Download className="mr-2 h-4 w-4" />
        {isLoading ? "Creating Backup..." : "Create System Backup"}
      </Button>
    </div>
  );
};