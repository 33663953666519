// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://xdhkcgssdbisyhgweaql.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhkaGtjZ3NzZGJpc3loZ3dlYXFsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQwMjg4NjgsImV4cCI6MjA0OTYwNDg2OH0.lWeYbpX4PcpylMOchO4Rue8e352Yjeq8T5LDKF_MeqE";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);