import { Priority } from "@/types/followUp";

export const getPriorityColor = (priority: Priority) => {
  switch (priority) {
    case "high":
      return "bg-red-500";
    case "medium":
      return "bg-yellow-500";
    case "low":
      return "bg-green-500";
    default:
      return "bg-gray-200";
  }
};

export const actionOptions = ["Meeting", "Email", "Call", "Task", "Review"];
export const statusOptions = ["completed", "pending", "on hold", "canceled", "other"] as const;
export const priorityOptions = ["high", "medium", "low"] as const;