import { useState } from "react";
import { Lead } from "@/types/lead";
import AddTradeShowForm from "@/components/panels/trade-shows/AddTradeShowForm";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import IndustriesSection from "./IndustriesSection";
import TradeShowsSection from "./TradeShowsSection";

interface TradeShowsPanelProps {
  selectedIndustry: string | null;
  onIndustrySelect: (industry: string) => void;
  onAddClick: () => void;
  refreshTrigger: number;
}

const TradeShowsPanel = ({
  selectedIndustry,
  onIndustrySelect,
  onAddClick,
  refreshTrigger,
}: TradeShowsPanelProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [industryToDelete, setIndustryToDelete] = useState<string | null>(null);

  return (
    <div className="space-y-4">
      <IndustriesSection
        selectedIndustry={selectedIndustry}
        onIndustrySelect={onIndustrySelect}
      />

      <TradeShowsSection
        selectedIndustry={selectedIndustry}
        refreshTrigger={refreshTrigger}
        onAddClick={onAddClick}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Industry</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this industry? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setIsDeleteDialogOpen(false);
              setIndustryToDelete(null);
            }}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => {
              // Handle delete
              setIsDeleteDialogOpen(false);
              setIndustryToDelete(null);
            }}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default TradeShowsPanel;
