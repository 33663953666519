import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { ListTodo, Plus, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface Todo {
  id: number;
  text: string;
  completed: boolean;
}

const TodoListPanel = () => {
  const { toast } = useToast();
  const [todos, setTodos] = useState<Todo[]>([]);
  const [newTask, setNewTask] = useState("");
  const [open, setOpen] = useState(false);

  const fetchTodos = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }

      const { data, error } = await supabase
        .from('todos')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching todos:', error);
        toast({
          title: "Error",
          description: "Failed to fetch todos",
          variant: "destructive",
        });
        return;
      }

      if (data) {
        setTodos(data);
      }
    } catch (error) {
      console.error('Error in fetchTodos:', error);
      toast({
        title: "Error",
        description: "Failed to fetch todos",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchTodos();

    // Subscribe to realtime changes
    const channel = supabase
      .channel('todos-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'todos'
        },
        () => {
          fetchTodos();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const handleAddTask = async () => {
    if (!newTask.trim()) {
      toast({
        title: "Error",
        description: "Please enter a task description",
        variant: "destructive",
      });
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }

      const { error } = await supabase
        .from('todos')
        .insert([
          { text: newTask.trim(), completed: false, user_id: user.id }
        ]);

      if (error) {
        console.error('Error adding todo:', error);
        toast({
          title: "Error",
          description: "Failed to add todo",
          variant: "destructive",
        });
        return;
      }

      setNewTask("");
      setOpen(false);
      toast({
        title: "Success",
        description: "Task added successfully",
      });
    } catch (error) {
      console.error('Error in handleAddTask:', error);
      toast({
        title: "Error",
        description: "Failed to add todo",
        variant: "destructive",
      });
    }
  };

  const handleDeleteTask = async (id: number) => {
    try {
      const { error } = await supabase
        .from('todos')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting todo:', error);
        toast({
          title: "Error",
          description: "Failed to delete todo",
          variant: "destructive",
        });
        return;
      }

      toast({
        title: "Success",
        description: "Task deleted successfully",
      });
    } catch (error) {
      console.error('Error in handleDeleteTask:', error);
      toast({
        title: "Error",
        description: "Failed to delete todo",
        variant: "destructive",
      });
    }
  };

  const handleToggleComplete = async (id: number, completed: boolean) => {
    try {
      const { error } = await supabase
        .from('todos')
        .update({ completed: !completed })
        .eq('id', id);

      if (error) {
        console.error('Error updating todo:', error);
        toast({
          title: "Error",
          description: "Failed to update todo",
          variant: "destructive",
        });
        return;
      }
    } catch (error) {
      console.error('Error in handleToggleComplete:', error);
      toast({
        title: "Error",
        description: "Failed to update todo",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50 border-b pb-3">
        <div className="flex items-center gap-2">
          <ListTodo className="w-5 h-5 text-gray-600" />
          <CardTitle className="text-lg font-medium text-gray-700">To-do List</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-3">
        <div className="space-y-2">
          {todos.map((todo) => (
            <div key={todo.id} className="flex items-center justify-between group">
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id={`todo-${todo.id}`} 
                  checked={todo.completed}
                  onCheckedChange={() => handleToggleComplete(todo.id, todo.completed)}
                  className="border-gray-400"
                />
                <label
                  htmlFor={`todo-${todo.id}`}
                  className={`text-xs text-gray-600 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
                    todo.completed ? 'line-through' : ''
                  }`}
                >
                  {todo.text}
                </label>
              </div>
              <button 
                onClick={() => handleDeleteTask(todo.id)}
                className="opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <Trash2 className="h-4 w-4 text-gray-400 hover:text-red-500" />
              </button>
            </div>
          ))}
        </div>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button 
              className="absolute top-3 right-4 bg-gray-100 text-gray-700 hover:bg-gray-200"
              size="icon"
            >
              <Plus className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-medium text-gray-700">Add New Task</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 py-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Task Description</label>
                <Input
                  type="text"
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  placeholder="Enter task description"
                />
              </div>
              <Button 
                className="w-full bg-gray-700 hover:bg-gray-800 text-white"
                onClick={handleAddTask}
              >
                Add Task
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default TodoListPanel;