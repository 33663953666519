import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

interface BasicInfoFieldsProps {
  formData: {
    company: string;
    contact: string;
    industry: string;
  };
  onChange: (field: string, value: string) => void;
}

export const BasicInfoFields = ({ formData, onChange }: BasicInfoFieldsProps) => {
  const [industries, setIndustries] = useState<{ name: string; color: string; }[]>([]);

  useEffect(() => {
    // Fetch initial industries
    const fetchIndustries = async () => {
      const { data: industriesData, error } = await supabase
        .from('industries')
        .select('name, color');
      
      if (error) {
        console.error('Error fetching industries:', error);
        return;
      }

      if (industriesData) {
        setIndustries(industriesData);
      }
    };

    fetchIndustries();

    // Subscribe to realtime changes
    const channel = supabase
      .channel('industries-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'industries'
        },
        () => {
          fetchIndustries(); // Refetch industries when changes occur
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <>
      <div className="col-span-2">
        <Label htmlFor="company">Company Name *</Label>
        <Input
          id="company"
          value={formData.company}
          onChange={(e) => onChange("company", e.target.value)}
          required
          className="h-8"
        />
      </div>

      <div>
        <Label htmlFor="contact">Contact Person</Label>
        <Input
          id="contact"
          value={formData.contact}
          onChange={(e) => onChange("contact", e.target.value)}
          className="h-8"
        />
      </div>

      <div>
        <Label htmlFor="industry">Industry *</Label>
        <Select
          value={formData.industry}
          onValueChange={(value) => onChange("industry", value)}
          required
        >
          <SelectTrigger id="industry" className="h-8">
            <SelectValue placeholder="Select industry" />
          </SelectTrigger>
          <SelectContent>
            {industries.map((industry) => (
              <SelectItem key={industry.name} value={industry.name}>
                <div className="flex items-center gap-2">
                  <div className={`h-2 w-2 rounded-full ${industry.color}`} />
                  {industry.name}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
};