import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useNavigate } from "react-router-dom";
import { Info, Mail, Building2 } from "lucide-react";

export const MobileMenu = () => {
  const navigate = useNavigate();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button 
          variant="ghost" 
          size="icon" 
          className="relative z-50 p-2 hover:bg-gray-100 rounded-lg"
        >
          <Menu className="h-6 w-6 text-gray-700" />
          <span className="sr-only">Open menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="w-[300px] bg-white">
        <SheetHeader>
          <SheetTitle>Menu</SheetTitle>
        </SheetHeader>
        <div className="flex flex-col gap-4 mt-6">
          <Button variant="ghost" onClick={() => navigate('/about')} className="justify-start">
            <Info className="mr-2 h-5 w-5" />
            About Us
          </Button>
          <Button variant="ghost" onClick={() => navigate('/contact')} className="justify-start">
            <Mail className="mr-2 h-5 w-5" />
            Contact
          </Button>
          <Button variant="ghost" onClick={() => navigate('/plans')} className="justify-start">
            <Building2 className="mr-2 h-5 w-5" />
            Plans
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};