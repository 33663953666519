import { useState } from "react";
import Header from "@/components/Header";
import TradeShowsPanel from "@/components/panels/trade-shows/TradeShowsPanel";
import LeadDashboardPanel from "@/components/panels/trade-shows/LeadDashboardPanel";
import { Lead } from "@/types/lead";
import AddTradeShowForm from "@/components/panels/trade-shows/AddTradeShowForm";
import {
  Dialog,
  DialogContent,
} from "@/components/ui/dialog";

const TradeShows = () => {
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);

  const handleAddClick = () => {
    setShowAddForm(true);
  };

  const handleAddSuccess = () => {
    setShowAddForm(false);
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-12 gap-4">
          {/* Lead Dashboard Panel - Middle */}
          <div className="col-span-12 lg:col-span-9">
            <LeadDashboardPanel
              selectedLead={selectedLead}
              onAddNote={() => {/* Add note handler */}}
              onLeadDeleted={() => {
                setSelectedLead(null);
              }}
              onLeadEdit={(lead) => {
                setSelectedLead(lead);
              }}
            />
          </div>

          {/* Trade Shows and Industries - Right */}
          <div className="col-span-12 lg:col-span-3">
            <TradeShowsPanel
              selectedIndustry={selectedIndustry}
              onIndustrySelect={setSelectedIndustry}
              onAddClick={handleAddClick}
              refreshTrigger={refreshTrigger}
            />
          </div>
        </div>

        <Dialog open={showAddForm} onOpenChange={setShowAddForm}>
          <DialogContent>
            <AddTradeShowForm
              type="exhibiting"
              selectedIndustry={selectedIndustry}
              onSuccess={handleAddSuccess}
              onClose={() => setShowAddForm(false)}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default TradeShows;