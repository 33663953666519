import { useState } from "react";
import TodoListPanel from "@/components/panels/TodoListPanel";
import WeeklyCalendarPanel from "@/components/panels/WeeklyCalendarPanel";
import IndustriesPanel from "@/components/panels/IndustriesPanel";
import ClientsListPanel from "@/components/panels/ClientsListPanel";
import ClientInfoPanel from "@/components/panels/ClientInfoPanel";
import FollowUpPanel from "@/components/panels/FollowUpPanel";
import { Client } from "@/types/client";
import Header from "@/components/Header";

const Index = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-2 space-y-4">
            <ClientInfoPanel selectedClient={selectedClient} />
            <IndustriesPanel onIndustrySelect={(industry) => setSelectedIndustry(industry)} />
            <ClientsListPanel 
              selectedIndustry={selectedIndustry}
              onClientSelect={(client) => setSelectedClient(client)}
              selectedClient={selectedClient}
            />
          </div>

          <div className="col-span-12 lg:col-span-7 space-y-4">
            <WeeklyCalendarPanel />
            <FollowUpPanel selectedClient={selectedClient} />
          </div>

          <div className="col-span-12 lg:col-span-3 space-y-4">
            <TodoListPanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;