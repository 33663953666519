import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface ProfileEditFormProps {
  initialData: {
    username: string;
    company_name: string;
    role: string;
  };
  onSuccess: () => void;
}

export const ProfileEditForm = ({ initialData, onSuccess }: ProfileEditFormProps) => {
  const { toast } = useToast();
  const [editForm, setEditForm] = useState({
    username: initialData.username,
    company_name: initialData.company_name || '',
    role: initialData.role || ''
  });

  const handleSaveProfile = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return;

    const { error } = await supabase
      .from('profiles')
      .update({
        username: editForm.username,
        company_name: editForm.company_name,
        role: editForm.role
      })
      .eq('id', session.user.id);

    if (error) {
      toast({
        title: "Error",
        description: "Failed to update profile. Please try again.",
        variant: "destructive",
      });
    } else {
      toast({
        title: "Success",
        description: "Profile updated successfully",
      });
      onSuccess();
    }
  };

  return (
    <div className="space-y-4 py-4">
      <div className="space-y-2">
        <Label htmlFor="username">Username</Label>
        <Input
          id="username"
          value={editForm.username}
          onChange={(e) => setEditForm({ ...editForm, username: e.target.value })}
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="company">Company Name</Label>
        <Input
          id="company"
          value={editForm.company_name}
          onChange={(e) => setEditForm({ ...editForm, company_name: e.target.value })}
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="role">Role</Label>
        <Input
          id="role"
          value={editForm.role}
          onChange={(e) => setEditForm({ ...editForm, role: e.target.value })}
        />
      </div>
      <Button onClick={handleSaveProfile} className="w-full">
        Save Changes
      </Button>
    </div>
  );
};