import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Info } from "lucide-react";
import Header from "@/components/Header";

const About = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="p-4">
        <div className="max-w-3xl mx-auto space-y-6">
          <Card>
            <CardHeader className="bg-gray-50 border-b pb-3">
              <div className="flex items-center gap-2">
                <Info className="w-5 h-5 text-gray-600" />
                <CardTitle className="text-lg font-medium text-gray-700">About Us</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="p-6 space-y-4">
              <p className="text-gray-600">
                SalesBy is a cutting-edge sales management platform designed to streamline your business operations. 
                Our platform helps businesses of all sizes manage their client relationships, track sales activities, 
                and boost productivity through intuitive tools and analytics.
              </p>
              <p className="text-gray-600">
                Founded with the mission to simplify sales processes, we're committed to providing solutions that 
                help businesses grow and succeed in today's competitive market.
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default About;