import { Client } from "@/types/client";

interface ClientsListProps {
  clients: Client[];
  selectedClient: Client | null;
  onClientSelect: (client: Client) => void;
  industries: { name: string; color: string; }[];
}

export const ClientsList = ({ 
  clients, 
  selectedClient, 
  onClientSelect,
  industries 
}: ClientsListProps) => {
  return (
    <div className="w-full">
      {clients.map((client) => (
        <div
          key={client.id}
          onClick={() => onClientSelect(client)}
          className={`flex items-center justify-between gap-1.5 group px-4 py-2 hover:bg-gray-50 cursor-pointer ${
            selectedClient?.id === client.id ? 'bg-gray-50' : ''
          }`}
        >
          <div className="flex items-center gap-1.5">
            <div className={`h-1 w-1 rounded-full ${
              industries.find(i => i.name === client.industry)?.color || 'bg-gray-400'
            }`}></div>
            <span className="text-xs">{client.company}</span>
          </div>
        </div>
      ))}
    </div>
  );
};