import { BackupControl } from "@/components/admin/BackupControl";
import Header from "@/components/Header";

const Admin = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Admin Control Panel</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <BackupControl />
          {/* More admin controls can be added here in the future */}
        </div>
      </div>
    </div>
  );
};

export default Admin;