import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CalendarFormData } from "@/types/calendar";

interface CalendarEventFormProps {
  formData: CalendarFormData;
  setFormData: (data: CalendarFormData) => void;
  handleSubmit: (e: React.FormEvent) => void;
  weekDays: string[];
}

const CalendarEventForm = ({ formData, setFormData, handleSubmit, weekDays }: CalendarEventFormProps) => {
  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
      <div>
        <Input 
          type="number" 
          placeholder="Week number (1-53)"
          className="w-full"
          value={formData.weekNumber}
          onChange={(e) => {
            const num = parseInt(e.target.value);
            if (!isNaN(num) && num >= 1 && num <= 53) {
              setFormData({ ...formData, weekNumber: e.target.value });
            }
          }}
        />
      </div>
      <div>
        <Select 
          value={formData.day}
          onValueChange={(value) => setFormData({ ...formData, day: value })}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select day" />
          </SelectTrigger>
          <SelectContent>
            {weekDays.map((day) => (
              <SelectItem key={day} value={day}>
                {day}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <Input 
          maxLength={14}
          placeholder="Event name"
          className="w-full"
          value={formData.event}
          onChange={(e) => setFormData({ ...formData, event: e.target.value })}
        />
      </div>
      <Button 
        type="submit"
        className="w-full bg-gray-700 hover:bg-gray-800 text-white"
        disabled={!formData.weekNumber || !formData.day || !formData.event}
      >
        Add Event
      </Button>
    </form>
  );
};

export default CalendarEventForm;