import { Edit2, Trash2 } from "lucide-react";
import { FollowUp, SortConfig } from "@/types/followUp";
import { getPriorityColor } from "@/utils/followUpUtils";

interface FollowUpTableProps {
  followUps: FollowUp[];
  sortConfig: SortConfig | null;
  handleSort: (key: keyof FollowUp) => void;
  handleEdit: (followUp: FollowUp) => void;
  handleDelete: (id: string) => void;
}

const FollowUpTable = ({ 
  followUps, 
  sortConfig, 
  handleSort, 
  handleEdit, 
  handleDelete 
}: FollowUpTableProps) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th onClick={() => handleSort('date')} className="text-left p-2 text-xs font-medium cursor-pointer hover:bg-gray-50">
              Date {sortConfig?.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('action')} className="text-left p-2 text-xs font-medium cursor-pointer hover:bg-gray-50">
              Action {sortConfig?.key === 'action' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('description')} className="text-left p-2 text-xs font-medium cursor-pointer hover:bg-gray-50">
              Description {sortConfig?.key === 'description' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('next_step')} className="text-left p-2 text-xs font-medium cursor-pointer hover:bg-gray-50">
              Next Step {sortConfig?.key === 'next_step' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('status')} className="text-left p-2 text-xs font-medium cursor-pointer hover:bg-gray-50">
              Status {sortConfig?.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('priority')} className="text-center p-2 text-xs font-medium cursor-pointer hover:bg-gray-50">
              Priority {sortConfig?.key === 'priority' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th className="text-left p-2 text-xs font-medium">Actions</th>
          </tr>
        </thead>
        <tbody>
          {followUps.map((item) => (
            <tr 
              key={item.id} 
              className="border-b last:border-b-0 hover:bg-gray-50 transition-colors"
            >
              <td className="p-2 text-xs">{item.date}</td>
              <td className="p-2 text-xs">{item.action}</td>
              <td className="p-2 text-xs">{item.description}</td>
              <td className="p-2 text-xs">{item.next_step}</td>
              <td className="p-2 text-xs">{item.status === 'other' ? item.custom_status : item.status}</td>
              <td className="p-2 text-xs text-center">
                <div className="inline-flex justify-center">
                  <div className={`w-3 h-3 rounded-full ${getPriorityColor(item.priority)}`} />
                </div>
              </td>
              <td className="p-2 flex gap-2">
                <button 
                  onClick={() => handleEdit(item)}
                  className="hover:text-blue-500 transition-colors"
                >
                  <Edit2 className="h-4 w-4" />
                </button>
                <button 
                  onClick={() => handleDelete(item.id)}
                  className="hover:text-red-500 transition-colors"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FollowUpTable;