import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Users2, Plus } from "lucide-react";
import { Client } from "@/types/client";
import { supabase } from "@/integrations/supabase/client";
import { ClientsList } from "@/components/clients/ClientsList";
import ClientForm from "@/components/clients/ClientForm";
import PaginationControls from "@/components/PaginationControls";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

interface ClientsListPanelProps {
  selectedIndustry: string;
  onClientSelect: (client: Client) => void;
  selectedClient: Client | null;
}

const ClientsListPanel = ({ selectedIndustry, onClientSelect, selectedClient }: ClientsListPanelProps) => {
  const { toast } = useToast();
  const [showForm, setShowForm] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [industries, setIndustries] = useState<{ name: string; color: string; }[]>([
    { name: "Technology", color: "bg-blue-400" },
    { name: "Healthcare", color: "bg-green-400" },
    { name: "Finance", color: "bg-purple-400" },
    { name: "Manufacturing", color: "bg-yellow-400" },
    { name: "Retail", color: "bg-red-400" },
    { name: "Other", color: "bg-gray-400" }
  ]);
  const itemsPerPage = 10;

  const fetchClients = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }

      let query = supabase
        .from('clients')
        .select('*')
        .eq('user_id', user.id);

      if (selectedIndustry) {
        query = query.eq('industry', selectedIndustry);
      }

      const { data, error } = await query.order('company');
      if (error) {
        console.error('Error fetching clients:', error);
        toast({
          title: "Error",
          description: "Failed to fetch clients",
          variant: "destructive",
        });
        return;
      }
      
      if (data) {
        console.log('Fetched clients:', data);
        setClients(data);
      }
    } catch (error) {
      console.error('Error in fetchClients:', error);
      toast({
        title: "Error",
        description: "Failed to fetch clients",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchClients();
  }, [selectedIndustry]);

  const totalPages = Math.ceil(clients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClients = clients.slice(startIndex, endIndex);

  const handleSuccess = () => {
    setShowForm(false);
    fetchClients();
  };

  const handleCancel = () => {
    setShowForm(false);
  };

  return (
    <Card className="flex flex-col border shadow-sm">
      <CardHeader className="bg-gray-50 border-b pb-3 flex-none">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Users2 className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Clients</CardTitle>
          </div>
          <Button 
            onClick={() => setShowForm(true)}
            className="bg-gray-100 text-gray-700 hover:bg-gray-200"
            size="icon"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-0 flex-1 flex flex-col">
        {showForm ? (
          <div className="p-3">
            <ClientForm onSuccess={handleSuccess} onCancel={handleCancel} />
          </div>
        ) : (
          <>
            <div className="flex-1">
              <ClientsList
                clients={currentClients}
                selectedClient={selectedClient}
                onClientSelect={onClientSelect}
                industries={industries}
              />
            </div>
            {clients.length > 0 && (
              <div className="p-3 border-t mt-auto">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ClientsListPanel;