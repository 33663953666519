import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import Header from "@/components/Header";

const Landing = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <Header />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center min-h-[80vh] text-center space-y-8 animate-fade-up">
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-black tracking-tight text-gray-900 max-w-4xl leading-tight">
            Transform Your Sales Process with
            <span className="bg-gradient-to-r from-[#ea384c] to-[#F97316] text-transparent bg-clip-text"> SalesBy</span>
          </h1>
          
          <p className="text-xl md:text-2xl text-gray-600 max-w-2xl">
            The intelligent follow-up system that helps sales teams close more deals through smart automation and insights
          </p>
          
          <div className="flex flex-col sm:flex-row gap-4 mt-8">
            <Button 
              size="lg"
              onClick={() => navigate("/register")}
              className="bg-gradient-to-r from-[#ea384c] to-[#F97316] text-white hover:opacity-90 px-8 py-6 text-lg"
            >
              Get Started Free
            </Button>
            
            <Button 
              size="lg"
              variant="outline"
              onClick={() => navigate("/login")}
              className="border-gray-300 text-gray-700 hover:bg-gray-50 px-8 py-6 text-lg"
            >
              Sign In
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Landing;