import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FollowUp, Priority, Status } from "@/types/followUp";
import { actionOptions, priorityOptions, statusOptions } from "@/utils/followUpUtils";

interface FollowUpFormProps {
  onSave: (followUp: Omit<FollowUp, 'id'>) => void;
  onCancel: () => void;
  editingFollowUp: FollowUp | null;
}

const FollowUpForm = ({ onSave, onCancel, editingFollowUp }: FollowUpFormProps) => {
  const [formData, setFormData] = useState<Omit<FollowUp, 'id'>>({
    date: '',
    description: '',
    next_step: '',
    action: '',
    status: 'pending',
    priority: 'medium',
    custom_status: null,
    created_at: new Date().toISOString(),
    user_id: '',
    client_id: 0
  });

  useEffect(() => {
    if (editingFollowUp) {
      setFormData({
        date: editingFollowUp.date,
        description: editingFollowUp.description,
        next_step: editingFollowUp.next_step,
        action: editingFollowUp.action,
        status: editingFollowUp.status,
        priority: editingFollowUp.priority,
        custom_status: editingFollowUp.custom_status || null,
        created_at: editingFollowUp.created_at,
        user_id: editingFollowUp.user_id,
        client_id: editingFollowUp.client_id
      });
    }
  }, [editingFollowUp]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <label className="text-sm font-medium">Date</label>
        <Input
          type="date"
          value={formData.date}
          onChange={(e) =>
            setFormData({ ...formData, date: e.target.value })
          }
          required
        />
      </div>
      <div className="space-y-2">
        <label className="text-sm font-medium">Action Type</label>
        <Select
          value={formData.action}
          onValueChange={(value) =>
            setFormData({ ...formData, action: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select action type" />
          </SelectTrigger>
          <SelectContent>
            {actionOptions.map((action) => (
              <SelectItem key={action} value={action}>
                {action}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-medium">Description</label>
        <Input
          type="text"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          placeholder="Enter description"
          required
        />
      </div>
      <div className="space-y-2">
        <label className="text-sm font-medium">Next Step</label>
        <Input
          type="text"
          value={formData.next_step}
          onChange={(e) =>
            setFormData({ ...formData, next_step: e.target.value })
          }
          placeholder="Enter next step"
          required
        />
      </div>
      <div className="space-y-2">
        <label className="text-sm font-medium">Status</label>
        <Select
          value={formData.status}
          onValueChange={(value: Status) =>
            setFormData({ ...formData, status: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select status" />
          </SelectTrigger>
          <SelectContent>
            {statusOptions.map((status) => (
              <SelectItem key={status} value={status}>
                {status}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {formData.status === 'other' && (
          <Input
            type="text"
            value={formData.custom_status || ''}
            onChange={(e) =>
              setFormData({ ...formData, custom_status: e.target.value })
            }
            placeholder="Enter custom status"
            className="mt-2"
          />
        )}
      </div>
      <div className="space-y-2">
        <label className="text-sm font-medium">Priority</label>
        <Select
          value={formData.priority}
          onValueChange={(value: Priority) =>
            setFormData({ ...formData, priority: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select priority" />
          </SelectTrigger>
          <SelectContent>
            {priorityOptions.map((priority) => (
              <SelectItem key={priority} value={priority}>
                {priority}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex gap-2 justify-end">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">
          {editingFollowUp ? 'Update Follow-up' : 'Add Follow-up'}
        </Button>
      </div>
    </form>
  );
};

export default FollowUpForm;