import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="flex items-center gap-2">
      <Link 
        to="/"
        className="flex items-center hover:opacity-80 transition-opacity"
      >
        <span className="text-2xl font-black tracking-tighter uppercase">
          SALES
          <span className="text-red-600 font-black">by</span>
        </span>
      </Link>
    </div>
  );
};

export default Logo;