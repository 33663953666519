import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Phone, Mail, MessageSquarePlus } from "lucide-react";
import { Lead } from "@/types/lead";
import { useEffect, useState } from "react";

interface LeadNote {
  id: number;
  note_text: string;
  created_at: string;
}

interface LeadDashboardPanelProps {
  selectedLead: Lead | null;
  onAddNote: () => void;
  onLeadDeleted?: () => void;
  onLeadEdit?: (lead: Lead) => void;
}

const LeadDashboardPanel = ({ selectedLead, onAddNote, onLeadDeleted, onLeadEdit }: LeadDashboardPanelProps) => {
  const { toast } = useToast();
  const [notes, setNotes] = useState<LeadNote[]>([]);

  useEffect(() => {
    if (selectedLead) {
      fetchNotes();
    }
  }, [selectedLead]);

  const fetchNotes = async () => {
    if (!selectedLead) return;

    const { data, error } = await supabase
      .from('trade_show_lead_notes')
      .select('*')
      .eq('lead_id', selectedLead.id)
      .order('created_at', { ascending: true });

    if (error) {
      toast({
        title: "Error",
        description: "Failed to fetch notes",
        variant: "destructive",
      });
      return;
    }

    if (data) {
      setNotes(data);
    }
  };

  if (!selectedLead) {
    return (
      <Card className="h-full">
        <div className="p-4 text-center text-gray-500">
          Select a lead to view details
        </div>
      </Card>
    );
  }

  return (
    <Card className="h-full">
      <div className="p-4">
        <div className="space-y-4">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-lg font-medium">{selectedLead.company}</h3>
              <p className="text-sm text-gray-500">{selectedLead.contact_name}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm">
                <span className="font-medium">Email:</span>{' '}
                <a href={`mailto:${selectedLead.email}`} className="text-blue-600 hover:underline">
                  {selectedLead.email}
                </a>
              </p>
              <p className="text-sm">
                <span className="font-medium">Phone:</span>{' '}
                <a href={`tel:${selectedLead.phone}`} className="text-blue-600 hover:underline">
                  {selectedLead.phone}
                </a>
              </p>
              {selectedLead.city && (
                <p className="text-sm">
                  <span className="font-medium">Location:</span>{' '}
                  {[selectedLead.city, selectedLead.state, selectedLead.country].filter(Boolean).join(', ')}
                </p>
              )}
            </div>
            <div>
              <p className="text-sm">
                <span className="font-medium">Interest:</span>{' '}
                {selectedLead.product_interest}
              </p>
              {selectedLead.immediate_actions && (
                <p className="text-sm">
                  <span className="font-medium">Immediate Actions:</span>{' '}
                  {selectedLead.immediate_actions}
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            <Button
              size="sm"
              variant="outline"
              onClick={() => window.location.href = `tel:${selectedLead.phone}`}
            >
              <Phone className="w-4 h-4 mr-2" />
              Call
            </Button>
            <Button
              size="sm"
              variant="outline"
              onClick={() => window.location.href = `mailto:${selectedLead.email}`}
            >
              <Mail className="w-4 h-4 mr-2" />
              Email
            </Button>
            <Button
              size="sm"
              variant="outline"
              onClick={onAddNote}
            >
              <MessageSquarePlus className="w-4 h-4 mr-2" />
              Add Note
            </Button>
          </div>

          <div className="space-y-2">
            <p className="text-sm font-medium">Notes:</p>
            <div className="space-y-2">
              {notes.map((note) => (
                <div key={note.id} className="bg-gray-50 p-3 rounded-md">
                  <div className="flex items-start gap-2">
                    <span className="text-gray-400">•</span>
                    <div className="flex-1">
                      <p className="text-sm text-gray-700">{note.note_text}</p>
                      <p className="text-xs text-gray-400 mt-1">
                        {new Date(note.created_at).toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default LeadDashboardPanel;