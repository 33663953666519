import { useEffect, useState } from "react";
import Header from "@/components/Header";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { RegistrationForm } from "@/components/plans/RegistrationForm";
import { StripePricingTable } from "@/components/plans/StripePricingTable";

const Plans = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          toast({
            title: "Already logged in",
            description: "You already have access to the platform",
          });
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error checking session:", error);
      }
    };

    // Handle successful payment redirect
    const handlePaymentSuccess = async () => {
      const paymentStatus = searchParams.get('payment');
      const email = searchParams.get('email');
      
      if (paymentStatus === 'success' && email) {
        // Get stored credentials
        const tempEmail = localStorage.getItem('tempEmail');
        const tempPassword = localStorage.getItem('tempPassword');
        
        if (tempEmail === email && tempPassword) {
          try {
            // Sign in the user
            const { error } = await supabase.auth.signInWithPassword({
              email: tempEmail,
              password: tempPassword,
            });

            if (error) throw error;

            // Clear stored credentials
            localStorage.removeItem('tempEmail');
            localStorage.removeItem('tempPassword');

            toast({
              title: "Welcome!",
              description: "Your account has been activated. Redirecting to dashboard...",
            });

            // Redirect to dashboard
            navigate("/dashboard");
          } catch (error: any) {
            console.error('Error signing in:', error);
            toast({
              title: "Error",
              description: "There was an error signing you in. Please try logging in manually.",
              variant: "destructive"
            });
          }
        }
      }
    };

    checkSession();
    handlePaymentSuccess();
  }, [navigate, toast, searchParams]);

  const testStripeConnection = async () => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase.functions.invoke('test-stripe');
      
      if (error) {
        console.error('Supabase function error:', error);
        toast({
          variant: "destructive",
          title: "Connection Error",
          description: error.message || "Failed to test Stripe connection",
        });
        return;
      }
      
      if (data?.success) {
        toast({
          title: "Success!",
          description: data.message,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Connection Error",
          description: data?.error || "Unknown error occurred",
        });
      }
    } catch (error: any) {
      console.error('Error testing Stripe:', error);
      toast({
        variant: "destructive",
        title: "Connection Error",
        description: "Failed to test Stripe connection. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto py-8 px-4">
        <div className="max-w-4xl mx-auto">
          <Button 
            onClick={testStripeConnection}
            disabled={loading}
            className="mb-8"
          >
            {loading ? "Testing..." : "Test Stripe Connection"}
          </Button>

          <RegistrationForm />

          <h2 className="text-2xl font-bold text-center mb-8">Choose Your Plan</h2>
          <StripePricingTable />
        </div>
      </div>
    </div>
  );
};

export default Plans;