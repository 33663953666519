import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { TradeShow } from "@/types/tradeShow";
import TradeShowCard from "./TradeShowCard";

interface TradeShowsListProps {
  selectedIndustry: string | null;
  refreshTrigger: number;
}

const TradeShowsList = ({ selectedIndustry, refreshTrigger }: TradeShowsListProps) => {
  const { toast } = useToast();
  const [tradeShows, setTradeShows] = useState<TradeShow[]>([]);

  const fetchTradeShows = async () => {
    try {
      console.log('Fetching trade shows for industry:', selectedIndustry);

      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to view trade shows",
          variant: "destructive",
        });
        return;
      }

      let query = supabase
        .from('trade_shows')
        .select('*')
        .eq('user_id', session.user.id);

      if (selectedIndustry) {
        query = query.eq('industry', selectedIndustry);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      console.log('Raw trade shows data:', data);

      if (data) {
        const typedShows: TradeShow[] = data.map(show => ({
          id: show.id,
          name: show.name,
          date: show.date,
          industry: show.industry,
          type: show.type as 'exhibiting' | 'visiting',
          city: show.city
        }));

        // Sort trade shows by date (earliest first)
        const sortedShows = typedShows.sort((a, b) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateA - dateB;
        });

        console.log('Processed and sorted trade shows:', sortedShows);
        setTradeShows(sortedShows);
      }
    } catch (error: any) {
      console.error('Error fetching trade shows:', error);
      toast({
        title: "Error",
        description: "Failed to fetch trade shows",
        variant: "destructive",
      });
    }
  };

  const handleDelete = (id: number) => {
    setTradeShows(current => current.filter(show => show.id !== id));
  };

  const handleTypeChange = (id: number, newType: 'exhibiting' | 'visiting') => {
    setTradeShows(current =>
      current.map(show =>
        show.id === id ? { ...show, type: newType } : show
      )
    );
  };

  useEffect(() => {
    console.log('TradeShowsList useEffect triggered with industry:', selectedIndustry);
    fetchTradeShows();
  }, [selectedIndustry, refreshTrigger]);

  if (tradeShows.length === 0) {
    return (
      <div className="p-4 text-center text-gray-500">
        No trade shows found{selectedIndustry ? ` for ${selectedIndustry}` : ''}.
      </div>
    );
  }

  return (
    <div className="space-y-2 p-4">
      {tradeShows.map((show) => (
        <TradeShowCard
          key={show.id}
          show={show}
          onDelete={handleDelete}
          onTypeChange={handleTypeChange}
        />
      ))}
    </div>
  );
};

export default TradeShowsList;