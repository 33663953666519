import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Calendar, Plus } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import CalendarEventForm from "../calendar/CalendarEventForm";
import CalendarTable from "../calendar/CalendarTable";
import { CalendarEvent, CalendarFormData, EventStatus } from "@/types/calendar";

const WeeklyCalendarPanel = () => {
  const [open, setOpen] = useState(false);
  const [activeWeeks, setActiveWeeks] = useState<number[]>([]);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [formData, setFormData] = useState<CalendarFormData>({
    weekNumber: "",
    day: "",
    event: "",
    status: "pending"
  });
  const { toast } = useToast();
  
  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    const savedEvents = localStorage.getItem('calendarEvents');
    const savedActiveWeeks = localStorage.getItem('activeWeeks');
    
    if (savedEvents) {
      setEvents(JSON.parse(savedEvents));
    }
    if (savedActiveWeeks) {
      setActiveWeeks(JSON.parse(savedActiveWeeks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('calendarEvents', JSON.stringify(events));
    localStorage.setItem('activeWeeks', JSON.stringify(activeWeeks));
  }, [events, activeWeeks]);

  const handleStatusChange = (week: number, day: string, text: string) => {
    setEvents(prev => prev.map(event => {
      if (event.week === week && event.day === day && event.text === text) {
        const statusMap: Record<EventStatus, EventStatus> = {
          'gray': 'done',
          'done': 'in-process',
          'in-process': 'pending',
          'pending': 'gray'
        };
        
        return { ...event, status: statusMap[event.status] };
      }
      return event;
    }));

    toast({
      title: "Status updated",
      description: "Event status has been changed successfully.",
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const weekNum = parseInt(formData.weekNumber);
    
    if (weekNum && formData.day && formData.event) {
      const existingEventsForDay = events.filter(
        event => event.week === weekNum && event.day === formData.day
      );

      if (existingEventsForDay.length >= 20) {
        toast({
          title: "Maximum events reached",
          description: "You can't add more than 20 events per day.",
          variant: "destructive"
        });
        return;
      }

      if (!activeWeeks.includes(weekNum)) {
        setActiveWeeks(prev => [...prev, weekNum].sort((a, b) => a - b));
      }
      
      setEvents(prev => [...prev, {
        week: weekNum,
        day: formData.day,
        text: formData.event,
        status: "pending"
      }]);
      
      setOpen(false);
      setFormData({ weekNumber: "", day: "", event: "", status: "pending" });
      toast({
        title: "Event added",
        description: "Your calendar event has been saved.",
      });
    }
  };

  const handleDeleteEvent = (week: number, day: string, text: string) => {
    setEvents(prev => prev.filter(event => 
      !(event.week === week && event.day === day && event.text === text)
    ));
    
    const remainingEventsForWeek = events.filter(
      event => event.week === week && !(event.week === week && event.day === day && event.text === text)
    );
    
    if (remainingEventsForWeek.length === 0) {
      setActiveWeeks(prev => prev.filter(w => w !== week));
    }

    toast({
      title: "Event deleted",
      description: "Your calendar event has been removed.",
    });
  };

  const minHeight = 80;
  const rowHeight = 25;
  const contentHeight = Math.max(minHeight, (activeWeeks.length * rowHeight) + 60);

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50 border-b pb-3">
        <div className="flex items-center gap-2">
          <Calendar className="w-5 h-5 text-gray-600" />
          <CardTitle className="text-lg font-medium text-gray-700">Weekly Calendar</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-0">
        <div 
          className="w-full overflow-x-auto overflow-y-auto transition-[height] duration-300 ease-in-out"
          style={{ height: `${contentHeight}px` }}
        >
          <CalendarTable 
            weekDays={weekDays}
            activeWeeks={activeWeeks}
            events={events}
            handleDeleteEvent={handleDeleteEvent}
            handleStatusChange={handleStatusChange}
          />
        </div>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button 
              className="absolute top-3 right-4 bg-gray-100 text-gray-700 hover:bg-gray-200"
              size="icon"
            >
              <Plus className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-medium text-gray-700">Add New Event</DialogTitle>
            </DialogHeader>
            <CalendarEventForm 
              formData={formData}
              setFormData={setFormData}
              handleSubmit={handleSubmit}
              weekDays={weekDays}
            />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default WeeklyCalendarPanel;