import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MessageSquarePlus, Plus } from "lucide-react";
import { FollowUp, Status, Priority } from "@/types/followUp";
import { Client } from "@/types/client";
import { supabase } from "@/integrations/supabase/client";
import FollowUpForm from "../follow-up/FollowUpForm";
import FollowUpTable from "../follow-up/FollowUpTable";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";

interface FollowUpPanelProps {
  selectedClient: Client | null;
}

const FollowUpPanel = ({ selectedClient }: FollowUpPanelProps) => {
  const [showForm, setShowForm] = useState(false);
  const [followUps, setFollowUps] = useState<FollowUp[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof FollowUp; direction: 'asc' | 'desc' } | null>(null);
  const { toast } = useToast();
  const [editingFollowUp, setEditingFollowUp] = useState<FollowUp | null>(null);

  const fetchFollowUps = async () => {
    const { data: session } = await supabase.auth.getSession();
    if (!session?.session?.user?.id) return;

    let query = supabase
      .from('follow_ups')
      .select('*')
      .eq('user_id', session.session.user.id);

    if (selectedClient) {
      query = query.eq('client_id', selectedClient.id);
    }

    const { data, error } = await query.order('created_at', { ascending: false });

    if (error) {
      toast({
        title: "Error",
        description: "Failed to fetch follow-ups",
        variant: "destructive",
      });
      return;
    }

    if (data) {
      setFollowUps(data.map(item => ({
        ...item,
        status: item.status as Status,
        priority: item.priority as Priority
      })));
    }
  };

  useEffect(() => {
    fetchFollowUps();
  }, [selectedClient]);

  const handleSave = async (followUpData: Omit<FollowUp, 'id'>) => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user?.id) throw new Error("No authenticated user");

      const newFollowUp = {
        ...followUpData,
        user_id: session.session.user.id,
        client_id: selectedClient?.id
      };

      const { error } = await supabase.from('follow_ups').insert([newFollowUp]);

      if (error) throw error;

      await fetchFollowUps();
      setShowForm(false);
      toast({
        title: "Success",
        description: "Follow-up saved successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to save follow-up",
        variant: "destructive",
      });
    }
  };

  const handleEdit = (followUp: FollowUp) => {
    setEditingFollowUp(followUp);
    setShowForm(true);
  };

  const handleDelete = async (id: string) => {
    const { error } = await supabase
      .from('follow_ups')
      .delete()
      .eq('id', id);

    if (error) {
      toast({
        title: "Error",
        description: "Failed to delete follow-up",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Success",
      description: "Follow-up deleted successfully",
    });
    fetchFollowUps();
  };

  const handleSort = (key: keyof FollowUp) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig?.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50 border-b pb-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <MessageSquarePlus className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Follow Ups</CardTitle>
          </div>
          <Button
            onClick={() => {
              setEditingFollowUp(null);
              setShowForm(true);
            }}
            className="bg-gray-100 text-gray-700 hover:bg-gray-200"
            size="icon"
            disabled={!selectedClient}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-3">
        {!selectedClient && (
          <div className="text-center text-gray-500 py-4">
            Select a client to view or add follow-ups
          </div>
        )}
        {selectedClient && showForm ? (
          <FollowUpForm
            onSave={handleSave}
            onCancel={() => {
              setShowForm(false);
              setEditingFollowUp(null);
            }}
            editingFollowUp={editingFollowUp}
          />
        ) : (
          <FollowUpTable
            followUps={followUps}
            sortConfig={sortConfig}
            handleSort={handleSort}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default FollowUpPanel;