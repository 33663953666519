import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { TradeShow } from "@/types/tradeShow";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface TradeShowCardProps {
  show: TradeShow;
  onDelete: (id: number) => void;
  onTypeChange: (id: number, newType: 'exhibiting' | 'visiting') => void;
}

const TradeShowCard = ({ show, onDelete, onTypeChange }: TradeShowCardProps) => {
  const { toast } = useToast();

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('trade_shows')
        .delete()
        .eq('id', show.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Trade show deleted successfully",
      });
      
      onDelete(show.id);
    } catch (error: any) {
      console.error('Error deleting trade show:', error);
      toast({
        title: "Error",
        description: "Failed to delete trade show",
        variant: "destructive",
      });
    }
  };

  const handleTypeChange = async () => {
    const newType = show.type === 'exhibiting' ? 'visiting' : 'exhibiting';
    
    try {
      const { error } = await supabase
        .from('trade_shows')
        .update({ type: newType })
        .eq('id', show.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Trade show type updated successfully",
      });
      
      onTypeChange(show.id, newType);
    } catch (error: any) {
      console.error('Error updating trade show type:', error);
      toast({
        title: "Error",
        description: "Failed to update trade show type",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="p-4 hover:bg-gray-50 relative">
      <div className="absolute top-4 right-4">
        <span
          onClick={handleTypeChange}
          className={`text-xs px-2 py-1 rounded-full cursor-pointer transition-colors ${
            show.type === 'exhibiting' 
              ? 'bg-blue-100 text-blue-800 hover:bg-blue-200' 
              : 'bg-green-100 text-green-800 hover:bg-green-200'
          }`}
        >
          {show.type}
        </span>
      </div>
      <div className="pr-24"> {/* Add padding to prevent text overlap with the tag */}
        <h3 className="font-medium">{show.name}</h3>
        <p className="text-sm text-gray-500">{show.date}</p>
        <p className="text-sm text-gray-500">{show.industry}</p>
        {show.city && (
          <p className="text-sm text-gray-500">{show.city}</p>
        )}
      </div>
      <div className="absolute bottom-4 right-4">
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8 hover:bg-red-100 hover:text-red-600"
          onClick={handleDelete}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    </Card>
  );
};

export default TradeShowCard;