import React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        'pricing-table-id': string;
        'publishable-key': string;
      };
    }
  }
}

export const StripePricingTable = () => {
  return (
    <stripe-pricing-table 
      pricing-table-id="prctbl_1QVN3OINLlI7zw9uTwjze5Tp"
      publishable-key="pk_test_51QVMv0INLlI7zw9uGWLaciGjRIsEo5YTBPSG8i3143Kr3Wkxg2SWJvUhXUy7bLTvmXKAQ7lAQOqiVFqFHpkHKxEt00BNaxYy56"
    />
  );
};