import { X } from "lucide-react";
import { CalendarEvent } from "@/types/calendar";

interface CalendarTableProps {
  weekDays: string[];
  activeWeeks: number[];
  events: CalendarEvent[];
  handleDeleteEvent: (week: number, day: string, text: string) => void;
  handleStatusChange: (week: number, day: string, text: string) => void;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "done":
      return "bg-green-500";
    case "in-process":
      return "bg-yellow-500";
    case "pending":
      return "bg-red-500";
    case "gray":
      return "bg-gray-500";
    default:
      return "bg-gray-500";
  }
};

const CalendarTable = ({ weekDays, activeWeeks, events, handleDeleteEvent, handleStatusChange }: CalendarTableProps) => {
  return (
    <table className="w-full border-collapse">
      <thead>
        <tr className="divide-x border-b">
          <th className="p-1 text-center bg-gray-50 font-medium text-gray-600 w-12 text-xs sticky top-0 z-10">
            Week
          </th>
          {weekDays.map((day) => (
            <th 
              key={day} 
              className="p-1 text-center bg-gray-50 font-medium text-gray-600 text-xs sticky top-0 z-10"
              style={{ width: '75px' }}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {activeWeeks.map((weekNum) => (
          <tr key={weekNum} className="divide-x border-b last:border-b-0">
            <td className="p-1 text-center bg-gray-50 font-medium text-gray-600 w-12 text-xs">
              {weekNum}
            </td>
            {weekDays.map((day) => {
              const dayEvents = events.filter(e => e.week === weekNum && e.day === day);
              return (
                <td
                  key={day}
                  className="p-1 text-center hover:bg-gray-50 transition-colors text-xs relative group"
                  style={{ width: '75px' }}
                >
                  <div className="flex flex-col gap-1">
                    {dayEvents.length > 0 ? (
                      dayEvents.map((event, index) => (
                        <div key={index} className="flex items-center justify-center gap-1">
                          <div
                            onClick={() => handleStatusChange(weekNum, day, event.text)}
                            className={`w-2 h-2 rounded-full ${getStatusColor(event.status)} cursor-pointer hover:opacity-80 transition-opacity`}
                            role="button"
                            tabIndex={0}
                            aria-label="Change status"
                          ></div>
                          <span className="truncate">{event.text}</span>
                          <button
                            onClick={() => handleDeleteEvent(weekNum, day, event.text)}
                            className="opacity-0 group-hover:opacity-100 transition-opacity text-gray-400 hover:text-red-500"
                          >
                            <X className="h-3 w-3" />
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="h-4">{"\u00A0"}</div>
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CalendarTable;