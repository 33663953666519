import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Building, Plus } from "lucide-react";
import TradeShowsList from "./TradeShowsList";

interface TradeShowsSectionProps {
  selectedIndustry: string | null;
  refreshTrigger: number;
  onAddClick: () => void;
}

const TradeShowsSection = ({
  selectedIndustry,
  refreshTrigger,
  onAddClick,
}: TradeShowsSectionProps) => {
  return (
    <Card>
      <CardHeader className="bg-gray-50 border-b pb-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Building className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Trade Shows</CardTitle>
          </div>
          <Button 
            variant="ghost" 
            size="icon"
            className="h-8 w-8"
            onClick={onAddClick}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <TradeShowsList 
        selectedIndustry={selectedIndustry}
        refreshTrigger={refreshTrigger}
      />
    </Card>
  );
};

export default TradeShowsSection;