import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { X } from "lucide-react";
import { BasicInfoFields } from "./form/BasicInfoFields";
import { ContactFields } from "./form/ContactFields";
import { AddressFields } from "./form/AddressFields";

const ClientForm = ({ onSuccess, onCancel }: { onSuccess: () => void; onCancel: () => void }) => {
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    company: "",
    contact: "",
    phone: "",
    mobile: "",
    email: "",
    web: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    industry: "",
  });

  const handleChange = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      const { error } = await supabase.from("clients").insert({
        ...formData,
        user_id: user.id,
        created_at: new Date().toISOString(),
      });

      if (error) throw error;

      toast({
        title: "Success",
        description: "Client added successfully",
      });
      
      onSuccess();
    } catch (error: any) {
      console.error("Error adding client:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to add client",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Add New Client</h3>
        <Button 
          type="button" 
          variant="ghost" 
          size="icon"
          onClick={onCancel}
        >
          <X className="h-4 w-4" />
        </Button>
      </div>

      <div className="max-h-[500px] overflow-y-auto pr-2">
        <div className="grid grid-cols-2 gap-3">
          <BasicInfoFields formData={formData} onChange={handleChange} />
          <ContactFields formData={formData} onChange={handleChange} />
          <AddressFields formData={formData} onChange={handleChange} />

          <div className="col-span-2">
            <Label htmlFor="notes">Notes</Label>
            <Textarea
              id="notes"
              value={formData.notes}
              onChange={(e) => handleChange("notes", e.target.value)}
              className="h-20 min-h-[5rem]"
            />
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-end pt-2 border-t mt-4">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">Add Client</Button>
      </div>
    </form>
  );
};

export default ClientForm;