import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { User } from "lucide-react";
import { Client } from "@/types/client";

interface ClientInfoPanelProps {
  selectedClient: Client | null;
}

const ClientInfoPanel = ({ selectedClient }: ClientInfoPanelProps) => {
  if (!selectedClient) {
    return (
      <Card className="h-fit relative border shadow-sm">
        <CardHeader className="bg-gray-50 border-b pb-3">
          <div className="flex items-center gap-2">
            <User className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Client Info</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-3">
          <p className="text-sm text-gray-500">Select a client to view details</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50 border-b pb-3">
        <div className="flex items-center gap-2">
          <User className="w-5 h-5 text-gray-600" />
          <CardTitle className="text-lg font-medium text-gray-700">Client Info</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-3">
        <div className="space-y-1.5">
          <p className="text-xs text-gray-600 font-bold">{selectedClient.company}</p>
          <p className="text-xs text-gray-600">{selectedClient.contact}</p>
          <a 
            href={`mailto:${selectedClient.email}`} 
            className="text-xs text-gray-600 hover:text-blue-800 hover:underline block"
          >
            {selectedClient.email}
          </a>
          <div className="flex gap-4">
            <div className="flex items-center gap-1">
              <span className="text-xs font-medium">P:</span>
              <a 
                href={`tel:${selectedClient.phone}`} 
                className="text-xs text-gray-600 hover:text-blue-600"
              >
                {selectedClient.phone}
              </a>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-xs font-medium">M:</span>
              <a 
                href={`tel:${selectedClient.mobile}`} 
                className="text-xs text-gray-600 hover:text-blue-600"
              >
                {selectedClient.mobile}
              </a>
            </div>
          </div>
          <a 
            href={selectedClient.web.startsWith('http') ? selectedClient.web : `http://${selectedClient.web}`}
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-xs text-gray-600 hover:text-blue-600 hover:underline"
          >
            {selectedClient.web}
          </a>
          
          <div className="space-y-0.5 mt-2">
            <p className="text-xs text-gray-600">{selectedClient.address}</p>
            <p className="text-xs text-gray-600">{selectedClient.city}, {selectedClient.state} {selectedClient.zip}</p>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-xs font-medium">Ind:</span>
            <p className="text-xs text-gray-600">{selectedClient.industry}</p>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-xs font-medium">Note:</span>
            <p className="text-xs text-gray-600">{selectedClient.notes}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ClientInfoPanel;